import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby";
import styled from "styled-components";
import { useStringsContext } from "~/contexts/strings-context";
import { Interweave } from "interweave";
import { gatsbyLinkTransform } from "~/utils/interweave-utils";
import { SEO } from "~/components/SEO";
import { PageProps } from "~/types/global";

const Container = styled.main`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .white {
      color: #ffffff;
    }
    .pink {
      color: #cc4d9b;
    }
`;

const PageNotFound = styled.p`
    font-size: 6em;
    font-weight: 600;
    color: #d8971f;
`;
const PageNotFoundWhite = styled(PageNotFound)`
    color: #afafaf;
`;

const Inline = styled(Link)`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 2rem;
    text-decoration: none;
    font-family: 'Play', sans-serif;
`;

const Column = styled(Inline)`
    flex-flow: column wrap;
    gap: 1rem;
    margin: 3rem 0 0 0;
    align-items: flex-start;
    font-family: 'IBM Plex Mono', monospace;
`;
const Code1 = styled.p`
      color: #0383b6;
`;
const Code2 = styled.p`
    color: #2c9c44;
    padding: 0 0 0 3rem;
`;
const Code3 = styled.p`
    color: #ffffff;
`;

const NotFoundPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();
  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="WANDERLUST TOURISM - 404"
        description="Page not found. please go to the home page 'https://www.wanderlusttourism.in'"
        keywords={["404", "page not found", "404 page not found"]}
        url={props.location.pathname}
      />
      <Container>
        <Inline to="/">
          <PageNotFound>{strings.get("pagenotfound1")}</PageNotFound>
          <PageNotFoundWhite>{strings.get("pagenotfound2")}</PageNotFoundWhite>
          <PageNotFound>{strings.get("pagenotfound1")}</PageNotFound>
        </Inline>
        <Column to="/">
          <Code1><Interweave tagName="p"
            transform={gatsbyLinkTransform}
            content={strings.get("error_messgae1")} /></Code1>
          <Code2><Interweave tagName="p"
            transform={gatsbyLinkTransform}
            content={strings.get("error_messgae2")} /></Code2>
          <Code3><Interweave tagName="p"
            transform={gatsbyLinkTransform}
            content={strings.get("error_messgae3")} /></Code3>
        </Column>
      </Container>
    </>
  )
};

export default NotFoundPage

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            pagenotfound1
            pagenotfound2
            error_messgae1
            error_messgae2
            error_messgae3
        }
    }
`;